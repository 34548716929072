const development = {
  apiHost: "http://localhost:8000",
  environment: 'development',
}

const all = {
  "http://localhost:8000": development,
  "http://localhost:3000": development,
  "https://8lh8dmll-3000.brs.devtunnels.ms": development,
  "https://8lh8dmll-8000.brs.devtunnels.ms": development,
  "https://weripass-staging.werify.eu": {
    apiHost: "https://weripass-staging.werify.eu",
    environment: 'staging',
  },
  "https://weripass-dca.werify.eu": {
    apiHost: "https://weripass-dca.werify.eu",
    environment: 'production',
  }
}

export const Settings = all[window.origin];
