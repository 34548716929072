import { List, Datagrid, ShowButton, TextField, Show, NumberField, Create, SimpleForm, useSafeSetState, SimpleFormIterator,
         SimpleShowLayout, required, TextInput, useTranslate, useRedirect, useDataProvider, email, ArrayInput,
         DateTimeInput,
         Edit,
         EditButton,
         FunctionField,
         Button,
         RichTextField
} from 'react-admin'
import { RichTextInput } from 'ra-input-rich-text';
import { PaginationDefault, defaultSort } from "../components/utils";
import {PermIdentity} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import { TopToolbarWithCreateButton } from "../components/top_toolbars";
import ParsedDateTextField from "../components/parsed_date_textfield";
import MyUrlField from "../components/my_url_field";
import { OnlySaveToolbar } from "../components/bottom_toolbars";
export const EventIcon = PermIdentity;

function EventList() {

  const eventFilters = [
    <FilterTextInput source="eventLike" alwaysOn/>,
    <FilterTextInput source="contributorLike" alwaysOn/>,
    <FilterTextInput source="organizerLike" alwaysOn />,
    <FilterTextInput source="sponsorLike" alwaysOn />,
    <FilterTextInput source="locationLike" alwaysOn />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={eventFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarWithCreateButton />}
    >
      {eventGrid}
    </List>
  );
}

const eventGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <TextField source="event" />
    <TextField source="contributor" />
    <TextField source="organizer" />
    <TextField source="sponsor" />
    <TextField source="location" />
    <ParsedDateTextField source="startDate" />
    <ShowButton />
    <EditButton />
  </Datagrid>

function EventShow(){
  const translate = useTranslate();
  return (
    <Show>
      <SimpleShowLayout>
        <NumberField source='id' />
        <TextField source="event" />
        <TextField source="description" />
        <TextField source="contributor" />
        <TextField source="organizer" />
        <TextField source="sponsor" />
        <TextField source="location" />
        <MyUrlField source="agenda" />
        <ParsedDateTextField source="startDate" />
        <ParsedDateTextField source="endDate" />
        <ParsedDateTextField source="doorTime" />
        <FunctionField render={ record => {
          return <Button
            href={`#/Participant?displayedFilters={"eventIdEq":true}&filter={"eventIdEq":${record.id}}`}
            label={translate("resources.Participant.name", {smart_count: 2})}
          >
          <PermIdentity />
        </Button>
        }} />
        <TextField source="emailLogoUrl" />
        <TextField source="emailTitle" />
        <TextField source="emailContent" />
      </SimpleShowLayout>
    </Show>
  );
}


const EventCreate = () => {
  return (
    <Create resource="Event" redirect="show">
      <SimpleForm>
        <TextInput source="event" validate={required()} autoComplete="off" />
        <TextInput source="description" validate={required()} autoComplete="off" />
        <TextInput source="contributor" validate={required()} autoComplete="off" />
        <TextInput source="organizer" validate={required()} autoComplete="off" />
        <TextInput source="sponsor" validate={required()} autoComplete="off" />
        <TextInput source="location" validate={required()} autoComplete="off" />
        <TextInput source="agenda" validate={required()} autoComplete="off" />
        <DateTimeInput source="startDate" validate={required()} />
        <DateTimeInput source="endDate" validate={required()} />
        <DateTimeInput source="doorTime" validate={required()} />
        <DateTimeInput source="expirationDate" validate={required()} />
        <TextInput source="emailLogoUrl" validate={required()} autoComplete="off" />
        <TextInput source="emailTitle" validate={required()} autoComplete="off" />
        <TextInput source="emailContent" validate={required()} fullWidth multiline minRows={10} />
      </SimpleForm>
    </Create>
  );
}


const EventTitle = ({ record }: {record?: any}) => {
  return <span>Org {record ? `"${record.about || record.id}"` : ''}</span>;
};

const EventEdit = () => {
  return (
    <Edit title={<EventTitle />} redirect="show">
      <SimpleForm toolbar={<OnlySaveToolbar />} warnWhenUnsavedChanges>
        <TextInput disabled source="id" />
        <TextInput source="event" validate={required()} autoComplete="off" />
        <TextInput source="description" validate={required()} autoComplete="off" />
        <TextInput source="contributor" validate={required()} autoComplete="off" />
        <TextInput source="organizer" validate={required()} autoComplete="off" />
        <TextInput source="sponsor" validate={required()} autoComplete="off" />
        <TextInput source="location" validate={required()} autoComplete="off" />
        <TextInput source="agenda" validate={required()} autoComplete="off" />
        <DateTimeInput source="startDate" validate={required()} />
        <DateTimeInput source="endDate" validate={required()} />
        <DateTimeInput source="doorTime" validate={required()} />
        <DateTimeInput source="expirationDate" validate={required()} />
        <TextInput source="emailLogoUrl" validate={required()} autoComplete="off" />
        <TextInput source="emailTitle" validate={required()} autoComplete="off" />
        <TextInput source="emailContent" validate={required()} fullWidth multiline minRows={10} />
      </SimpleForm>
    </Edit>
  )
};


export {EventList, EventShow, EventCreate, EventEdit, eventGrid};
