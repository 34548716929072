export const i18n = {
  en: {
    admin: {
      errors: {
        default: "Error: An error has ocurred",
        invalidPassword: "Invalid password or one-time password",
      },
    },
    resources: {
      Participant: {
        name: 'Participant |||| Participants',
        many: 'Participants',
        fields: {
          id: 'ID',
          emailAddress: 'Email Address',
          firstName: 'First Name',
          lastName: 'Last Name',
          company: 'Company',
          position: 'Position',
          lang: 'Language',
          langs: {
            EN: 'English',
            ES: 'Spanish',
            CA: 'Catalan',
            EU: 'Euskera',
            FR: 'French',
            IT: 'Italian',
            GL: 'Gallego',
            PT: 'Portuguese'
          },
          typeOfVisitor: 'Type of Visitor',
          typeOfVisitors: {
            ATTENDEE: 'Attendee',
            SPEAKER: 'Speaker',
            VIP: 'VIP',
            STAFF: 'Staff'
          },
          additionalData: 'Additional Data',
          registrationDate: 'Registration Date',
          eventIdEq: 'By Event:',
          emailAddressLike: 'By Email Address:',
          lastNameLike: 'By Last Name:',
          companyLike: 'By Company:',
        },
        uploadFromFile: "Upload from a file",
        selectManually: "Or enter manually",  
        createByValues: "Create by values", 
        errorUpdating: "Error updating the attribute list.",
      },
      Event: {
        name: 'Event |||| Events',
        many: 'Events',
        fields: {
          id: 'ID',
          event: 'Event',
          about: 'About',
          description: 'Description',
          contributor: 'Contributor',
          organizer: 'Organizer',
          sponsor: 'Sponsor',
          location: 'Location',
          agenda: 'Agenda',
          startDate: 'Start Date',
          endDate: 'End Date',
          doorTime: 'Door Time',
          emailLogoUrl: 'Email Logo URL',
          emailTitle: 'Email Title',
          emailContent: 'Email Content',
          eventLike: 'By Event:',
          contributorLike: 'By Contributor:',
          organizerLike: 'By Organizer:',
          sponsorLike: 'By Sponsor:',
          locationLike: 'By Location:',
        },
      },
      OutgoingEmailMessage: {
        name: 'Email Message |||| Email Messages',
        fields: {
          id: 'Nº',
          personId: 'Person',
          emailAddressId: 'Email Address',
          sent: 'Sent?',
          sentAt: 'Sent at',
          kind: 'Kind',
          kinds: {
            REGISTRATION: 'Registration'
          },
          customMessage: 'Custom Message',
          extraData: 'Extra Data',
          idEq: 'By Nº:',
          emailAddressIdEq: 'By Email Address:',
          sentAtIsSet: 'By Sent at',
        },
      },
      AdminUser: {
        name: 'Admin User |||| Admin Users',
        create: 'Create an Admin User',
        fields: {
          id: 'ID',
          username: 'Username',
          password: 'Password',
          otpSeed: 'OTP Seed',
          otp: 'OTP',
          role: 'Role',
          createdAt: 'Created At',
          usernameLike: 'By Username:',
          roleEq: 'By Role:',
          roles: {
            admin: 'Admin',
            superadmin: 'SuperAdmin',
          },
        },
      },
      ChangePassword: {
        name: 'Change Password',
        password: 'Password',
        newPassword: 'New Password',
        reEnterNewPassword: 'Re-enter new password',
        otp: 'OTP',
        confirmationFailed: 'Passwords must match',
      },
      actions: {
        createTemplate: 'Create Template',
        requestOrgDeletion: 'Request Org Deletion',
        cancelOrgDelete: 'Cancel Deletion',
        orgDeletionMessage: 'Be careful, once you request to delete an organization. The organization will not be able to access their own data.',
        orgDeletionCancelMessage: 'You are about to cancel the request to delete the organization, making all their data available again.',
        physicalDeletionConfirm: 'Be careful, you are about to permanently delete all data for this organization.',
        physicalDeletionCompleted: 'Physical deletion completed.',
        physicalDelete: 'Physical Delete',
        updated: 'Element updated',
        created: 'Element created',
        changePassword: 'Change Password',
        loading: 'Loading...',
        copy_to_clipboard: 'Link copied to clipboard',
        click_here: 'Click here to visit',
        details: 'Details',
        errorUpdatingResource: "Error: invalid fields for %{resource}.",
        errorInvalidFieldsResource: "Error: invalid fields.",
        createOne: "Create one",
        createFromFile: "or create many from a file",
        upload: "Upload CSV",
      },
    },
    patch: {
      auth: {
        auth_check_error: "Por favor inicie sesión para continuar"
      },
      action: {
        unselect: "Deseleccionar"
      },
      navigation: {
        skip_nav: "Saltar al contenido",
        page_rows_per_page: "Filas por página:"
      }
    }
  }
};
