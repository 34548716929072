import React, {useEffect, useState} from "react";
import { Admin, Resource, CustomRoutes } from 'react-admin';
import authProvider from './components/auth_provider';
import buildGraphQLProvider, { buildQuery } from 'ra-data-graphql-simple';
import {AdminUserList, AdminUserCreate} from "./views/admin_user";
import {ParticipantList, ParticipantShow, ParticipantCreate} from './views/participant';
import {
        PermIdentity, SupervisorAccount, Email, Event
} from '@mui/icons-material';
import { Login } from 'ra-ui-materialui';
import { LoginForm } from './views/login';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import MyLayout from './components/my_layout'
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import {i18n as adminMessages} from './i18n';
import { Route } from "react-router-dom";
import werifyTheme from "./theme";
import gql from 'graphql-tag';
import { EventList, EventShow, EventCreate, EventEdit } from "./views/event";
import { OutgoingEmailMessageList, OutgoingEmailMessageShow } from "./views/outgoing_email_message";
import { LoginRedirect } from "./views/login_redirect";

Login.defaultProps.children = <LoginForm />;

function App() {

  const api_url = `${process.env.REACT_APP_BACK_OFFICE_API || ''}/graphql/`
  const [dataProvider, setDataProvider] = useState(null);

  useEffect(() => {
    async function initApp() {
      const httpLink = new HttpLink({ uri: api_url });

      const authMiddleware = new ApolloLink((operation, forward) => {
        let authorization = authProvider.getToken() || null;
        operation.setContext(({ headers = {} }) => ({
          headers: {
          ...headers,
          "Authorization": authorization
          }
        }));
        
        return forward(operation);
      })

      const logoutLink = onError(({ networkError, graphQLErrors }) => {
        authProvider.checkError(networkError, graphQLErrors);
      })

      const client = new ApolloClient({
        link: from([
          authMiddleware,
          logoutLink,
          httpLink
        ]),
        cache: new InMemoryCache(),
      });
          
      const werifyAppBuildQuery = introspection => (fetchType, resource, params) => {
        if (resource === 'PhysicalDeletion') {

          const parser = function(data){
            if(data.data.data == null ){
              return { data: { done: true, id: true } };
            } else {
              return buildQuery(introspection)('GET_ONE', 'OrgDeletion', params).parseResponse(data);
            }
          }

          return {
            parseResponse: parser,
            variables: params.data,
            query: gql`mutation($org_deletion_id:Int!){
              data: physicalDeletion(orgDeletionId: $org_deletion_id) {
                id,
                completed,
              }
            }`
          };

        } else {
          return buildQuery(introspection)(fetchType, resource, params);
        }
      };

      buildGraphQLProvider({ client, buildQuery: werifyAppBuildQuery}).then(provider => { setDataProvider(() => provider) });
    }

    initApp();
  }, [api_url]);

  if (!dataProvider) {
    return <div> Loading </div>;
  }

  const messages = {
    en: { ...englishMessages, ...adminMessages.en },
  };
  const i18nProvider = polyglotI18nProvider(locale => messages[locale], "en");

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={Login}
      layout={MyLayout}
      theme={werifyTheme}
      >
      {permissions => [
        <Resource
          name="Event"
          list={EventList}
          show={EventShow}
          create={EventCreate}
          edit={EventEdit}
          icon={Event}
        />,
        <Resource
          name="Participant"
          list={ParticipantList}
          show={ParticipantShow}
          create={ParticipantCreate}
          icon={PermIdentity}
        />,
        <Resource
          name="OutgoingEmailMessage"
          list={OutgoingEmailMessageList}
          show={OutgoingEmailMessageShow}
          icon={Email}
        />,
        <CustomRoutes noLayout >
          <Route path="/login_redirect" element={<LoginRedirect />} />
        </CustomRoutes>,

        permissions === 'SuperAdmin'
          ? <Resource 
              name="AdminUser"
              list={AdminUserList}
              create={AdminUserCreate}
              icon={SupervisorAccount}
              options={{ label: 'Admin Users' }}
            />
          : null
      ]}
    </Admin>
  );
}

export default App;
